var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.getClientes}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-reload")]),_vm._v(" Recargar ")],1),_c('v-card',{staticClass:"mt-2"},[_c('v-data-table',{attrs:{"fixed-header":"","items":_vm.clientes,"headers":_vm.clientesHeader,"calculate-widths":true,"checkbox-color":"secondary","loading":_vm.loading,"footer-props":{ showFirstLastPage: true, showCurrentPage: true }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.clientes},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.Password",fn:function(ref){
var item = ref.item;
return [(item.Password != null && item.Password != '')?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.seePassword == null || _vm.seePassword != item.Identidad
							? (_vm.seePassword = item.Identidad)
							: (_vm.seePassword = null)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.seePassword == item.Identidad ? "mdi-eye-off" : "mdi-eye"))])],1):_vm._e(),(_vm.seePassword == item.Identidad)?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.Password))]):(item.Password != null && item.Password != '')?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(Array(item.Password.length + 1).join("•")))]):_vm._e()]}},{key:"item.CambioPassword",fn:function(ref){
							var item = ref.item;
return [(item.CambioPassword === '1')?_c('span',{domProps:{"innerHTML":_vm._s(
						"El cliente <strong style='color: var(--v-primary-base)'>ya</strong> ha cambiado la contraseña"
					)}}):(item.CambioPassword === '0')?_c('span',{domProps:{"innerHTML":_vm._s(
						"El cliente tiene una contraseña <strong style='color: var(--v-warning-base)'>temporal</strong>"
					)}}):(item.CambioPassword === null)?_c('span',{domProps:{"innerHTML":_vm._s(
						"El cliente aún <strong style='color: var(--v-error-base)'>no</strong> ha cambiado la contraseña"
					)}}):_vm._e()]}},{key:"item.acciones",fn:function(ref){
					var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.comp = 'crear-incidencia';
						_vm.idCliente = item.IdCliente;}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Crear incidencia ")],1),_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.comp = 'ficha-cliente';
						_vm.idCliente = item.IdCliente;}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" Ficha cliente ")],1),_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","outlined":"","color":"secondary","href":"https://oficinavirtual.alumbraenergia.es","target":"_blank"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" oficinavirtual ")],1)]}}])})],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","fullscreen":"","persistent":"","value":_vm.idCliente != null}},[_c(_vm.comp,{key:_vm.idCliente,tag:"component",attrs:{"idCliente":_vm.idCliente,"identidad":(_vm.clientes.find(function (c) { return c.IdCliente == _vm.idCliente; }) || {}).Identidad},on:{"close":function($event){_vm.idCliente = null}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }