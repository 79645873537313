<template>
	<v-container fluid>
		<v-btn small @click="getClientes" color="primary">
			<v-icon small left>mdi-reload</v-icon>
			Recargar
		</v-btn>
		<v-card class="mt-2">
			<v-data-table
				fixed-header
				:items="clientes"
				:headers="clientesHeader"
				:calculate-widths="true"
				checkbox-color="secondary"
				:loading="loading"
				:footer-props="{ showFirstLastPage: true, showCurrentPage: true }"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters
						:headers="headers"
						:items="clientes"
						v-model="inlineFilters"
					></table-filters>
				</template>

				<template v-slot:item.Password="{ item }">
					<v-btn
						v-if="item.Password != null && item.Password != ''"
						small
						icon
						@click="
							seePassword == null || seePassword != item.Identidad
								? (seePassword = item.Identidad)
								: (seePassword = null)
						"
					>
						<v-icon>{{
							seePassword == item.Identidad ? "mdi-eye-off" : "mdi-eye"
						}}</v-icon>
					</v-btn>
					<span class="ml-1" v-if="seePassword == item.Identidad">{{
						item.Password
					}}</span>
					<span
						class="ml-1"
						v-else-if="item.Password != null && item.Password != ''"
						>{{ Array(item.Password.length + 1).join("•") }}</span
					>
				</template>

				<template v-slot:item.CambioPassword="{ item }">
					<span
						v-if="item.CambioPassword === '1'"
						v-html="
							`El cliente <strong style='color: var(--v-primary-base)'>ya</strong> ha cambiado la contraseña`
						"
					/>
					<span
						v-else-if="item.CambioPassword === '0'"
						v-html="
							`El cliente tiene una contraseña <strong style='color: var(--v-warning-base)'>temporal</strong>`
						"
					/>
					<span
						v-else-if="item.CambioPassword === null"
						v-html="
							`El cliente aún <strong style='color: var(--v-error-base)'>no</strong> ha cambiado la contraseña`
						"
					/>
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-btn
						small
						outlined
						color="primary"
						@click="
							comp = 'crear-incidencia';
							idCliente = item.IdCliente;
						"
					>
						<v-icon small left>mdi-plus</v-icon>
						Crear incidencia
					</v-btn>
					<v-btn
						class="ml-1"
						small
						outlined
						color="primary"
						@click="
							comp = 'ficha-cliente';
							idCliente = item.IdCliente;
						"
					>
						<v-icon small left>mdi-alert-circle-outline</v-icon>
						Ficha cliente
					</v-btn>
					<!-- <v-btn
						class="ml-1"
						small
						outlined
						color="secondary"
						@click="openOficinaVirtual"
					>
						<v-icon small left>mdi-open-in-new</v-icon>
						oficinavirtual
					</v-btn> -->
					<v-btn
						class="ml-1"
						small
						outlined
						color="secondary"
						href="https://oficinavirtual.alumbraenergia.es"
						target="_blank"
					>
						<v-icon small left>mdi-open-in-new</v-icon>
						oficinavirtual
					</v-btn>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog
			transition="dialog-bottom-transition"
			fullscreen
			persistent
			:value="idCliente != null"
		>
			<component
				:is="comp"
				@close="idCliente = null"
				:key="idCliente"
				:idCliente="idCliente"
				:identidad="
					(clientes.find((c) => c.IdCliente == idCliente) || {}).Identidad
				"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import { processToken, perColumnFilter, parseDate } from "@/utils/index.js";

export default {
	components: {
		TableFilters: () => import("@/components/TableFilters.vue"),
		CrearIncidencia: () => import("@/components/CrearIncidencia.vue"),
		FichaCliente: () => import("@/components/FichaCliente.vue"),
	},
	data() {
		return {
			comp: null,
			idCliente: null,
			seePassword: null,
			loading: true,
			clientes: [],
			inlineFilters: {},
			clientesHeader: [
				{ text: "Identificador", value: "Identidad" },
				{ text: "Contraseña", value: "Password" },
				{ text: "Cambio Contraseña", value: "CambioPassword" },
				{
					text: "Acciones",
					value: "acciones",
					sortable: false,
					filterable: false,
				},
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
		};
	},
	methods: {
		getClientes() {
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_API_URL}/clientes.php`,
				params: {
					token: this.$store.getters.getToken,
				},
			}).then(({ data }) => {
				this.clientes = data;
				this.loading = false;
			});
		},
		openOficinaVirtual() {
			axios({
				method: "POST",
				url: `${process.env.VUE_APP_API_URL}/autologin.php`,
				data: {
					token: this.$store.getters.getToken,
				},
			}).then(({ data }) => {
				console.log(data)
			});
		}
	},
	mounted() {
		this.getClientes();
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	background: #f1f1f1 !important;
}
</style>